import React from "react";
import { Screen1Logo, StoreLabels } from "./screen_1";

export const Screen3 = () => {
  return (
    <div className=" w-full flex flex-col sm:py-14 pt-8 pb-12 sm:pb-24 gap-20 items-center sm:items-start mt-16 justify-between sm:max-lg:items-center">
      <DownloadApp />
      <Footer />
    </div>
  );
};

export const DownloadApp = () => {
  return (
    <div className="  w-full flex flex-col sm:flex-row  sm:items-stretch sm:max-lg:flex-col sm:max-lg:items-center sm:max-lg:w-[80%] ">
      <section className="flex-[2] bg-[#025B59] w-full px-2 pl:6 sm:pl-[4%] pt-9 sm:pt-[4%] pb-10 sm:pb-[5%] flex flex-col  rounded-tl-3xl rounded-tr-3xl  sm:rounded-tr-none rounded-bl-none  sm:rounded-bl-3xl items-center sm:items-center justify-center sm:max-lg:rounded-tr-3xl sm:max-lg:rounded-bl-none sm:max-lg:pb-14">
        <div className=" flex flex-col gap-[2.4rem] sm:max-lg:rounded-tr-3xl sm:max-lg:gap-[2.0rem] max-lg:items-center  ">
          <h2 className=" max-md:text-3xl text-4xl font-semibold">
            Download the App
          </h2>
          <p className="text-md sm:max-w-[635px] sm:text-lg sm:max-lg:max-w-[500px]  ">
            Monvial is a Digital Wallet born out of the passion to spearhead
            innovation in the fintech sector in Liberia. Our goal is to create
            financial opportunities for the unbanked and underbanked in Africa.{" "}
          </p>
          <div className="w-full items-stretch">
            <StoreLabels addStyle={"sm:max-lg:w-full sm:max-lg:gap-3 "} />
          </div>
        </div>
      </section>
      {/* <div className="outline sm:w-[100%] stretch flex flex-[1]  justify-end "> */}
      <img
        src={"/images/phone.png"}
        alt="phone"
        className="flex-1  lg:max-w-[38%] sm:max-lg:w-[100%] justify-end rounded-br-3xl rounded-bl-3xl sm:rounded-bl-none sm:rounded-tr-3xl hidden sm:block  rounded-tr-none sm:max-lg:rounded-bl-3xl sm:max-lg:rounded-tr-none  "
      />
      <img
        src={"/images/phone2.png"}
        alt="phone"
        className="w-full rounded-br-3xl rounded-bl-3xl sm:rounded-bl-none sm:rounded-tr-3xl block sm:hidden rounded-tr-none"
      />
      {/* </div> */}
    </div>
  );
};

const Footer = () => {
  return (
    <div className=" w-full flex flex-col gap-6">
      <FooterNote />
      <p className="text-sm text-[#98A2B3]">
        &copy; 2024 Sabinovates LLC. All rights reserved
      </p>
    </div>
  );
};
const FooterNote = () => {
  return (
    <div className="flex flex-col sm:flex-row gap-16 border-b-2 border-[#646566] pb-6">
      <div className="flex flex-col gap-7">
        <Screen1Logo />
        <p className="max-w-[358px] sm:max-w-[660px] text-lg  opacity-[.8]">
          Monival is a product of Sabinovates LLC based in Liberia with presence
          in the United States & Nigeria. Sabinovates is both a product
          development and financial technology company and is not a bank. We
          offer our products and services in partnership with licensed
          transmitters and banking partners in their respective jurisdictions.
        </p>
      </div>
      <div className="flex flex-row sm:ml-auto gap-14">
        <section className=" text-lg  flex flex-col gap-6">
          <Link id={"Terms of service"} />
          <Link id={"FAQ"} />
          <Link id={"Privacy policy"} />
        </section>
        <section className=" text-lg flex flex-col gap-6">
          <Link id={"AML/KYC POLICY"} />
          <Link id={"ISMS Policy"} />
        </section>
      </div>
    </div>
  );
};

const Link = ({ id }) => {
  return (
    <p className="text-white font-semibold hover:text-[#51983E] hover:cursor-pointer">
      {id}
    </p>
  );
};
