import "./App.css";
import { Screen1 } from "./screens/screen_1";
import { Screen2 } from "./screens/screen_2";
import { Screen3 } from "./screens/screen_3";

function App() {
  let background = "bg-gradient-to-l from-[#012F2E] via-[#012F2E] to-[#000000]";
  return (
    <main
      className={`font-graphik font-light  w-[100%] outline mx-auto px-0  text-white ${background} `}
    >
      <Screen1 />
      <div className="bg-gradient-to-tr from-[#010404] via-[#012F2E] px-[8%] to-[#012F2E] ">
        <Screen2 />
        <Screen3 />
      </div>
    </main>
  );
}

export default App;
