import React from "react";

export const Screen2A = () => {
  return (
    <div className="flex flex-col gap-[3rem] items-center py-[60px] p-2 ">
      <section className="sm:w-[580px]  flex flex-col items-center gap-5">
        <h3 className="text-sm px-[10px] py-[2px] bg-[#D9FFCF] text-black rounded-2xl text-justify ">
          Features
        </h3>
        <h1 className="text-3xl font-semibold text-center">
          The Digital Lifestyle Financial App
        </h1>
        <p className="text-lg text-center">
          A multi currency e-wallet that allows you to spend, send, save, and
          secure your money in an enjoyable user experience.
        </p>
      </section>

      {/* <section className="  items-center w-full flex flex-col sm:flex-row  sm:justify-between justify-between row gap-12"> */}
      <section className="w-full justify-center grid grid-cols-[minmax(0,300px)] grid_single:max-grid_break:grid-rows-2 grid_single:max-grid_break:grid-cols-4  grid_single:max-grid_break:w-[80%]  gap-[5%] auto-rows-[minmax(0,100%)] grid_break:grid-cols-3 ">
        <FeatureCard
          colSpan={
            "grid_single:max-grid_break:col-start-2 grid_single:max-grid_break:col-end-4"
          }
          w={"70px"}
          h={"70px"}
          bgIcon={`bg-[#51983E]`}
          bgColor={"bg-[#407D30]"}
          image={"card-wallet"}
          actionText={"Spend"}
          description={
            "A virtual VISA/Mastercard that fits your online lifestyle."
          }
        />
        <FeatureCard
          colSpan={
            "grid_single:max-grid_break:col-start-1 grid_single:max-grid_break:col-end-3"
          }
          w={"85px"}
          h={"85px"}
          bgIcon={`bg-[#025B59]`}
          bgColor={`bg-[#003E3D]`}
          image={"money-bag"}
          actionText={"Send"}
          description={"Send & receive money within our platform in seconds."}
        />
        <FeatureCard
          colSpan={
            "grid_single:max-grid_break:col-start-3 grid_single:max-grid_break:col-end-5"
          }
          w={"100px"}
          h={"70px"}
          bgIcon={`bg-[#007F7D]`}
          bgColor={"bg-[#025B59]"}
          image={"shield"}
          actionText={"Secure"}
          description={
            "Manage your money both USD & LRD with monival securely."
          }
        />
      </section>
      <DownloadCard />
    </div>
  );
};
const FeatureCard = ({
  w,
  h,
  colSpan,
  bgIcon,
  bgColor,
  image,
  actionText,
  description,
}) => {
  return (
    <div
      className={`${colSpan}  flex flex-col items-center justify-center gap-[0.5rem] ${bgColor} px-[4%]  py-[12%] hover:outline outline-1 outline-[rgb(217,255,207)] rounded-xl`}
    >
      {/* <div
        className={`w-[30%] h-[80%] rounded-full ${bgIcon} flex flex-row justify-center`}
      > */}
      <img
        src={`/images/${image}.png`}
        alt="guarantee"
        className={`max-w-[30%] object-contain p-5 ${bgIcon} rounded-full`}
      />{" "}
      {/* </div> */}
      <h2 className="max-lg:text-2xl text-3xl">{actionText}</h2>
      <p className="text-lg text-center xl:text-[130%] ">{description}</p>
    </div>
  );
};

const DownloadCard = () => {
  return (
    <div className="w-full gap-[6rem]  custom:items-start items-center  custom:w-full flex flex-col custom:flex-row  align-top justify-between custom:pl-[4%] pt-[4%] mt-[4rem] rounded-3xl border-[#D9FFCF] hover:border-[1px] bg-[#012F2E] custom:max-lg:flex-col custom:max-lg:gap-[6rem] custom:max-lg:items-center">
      <section className=" flex-1 flex flex-col  custom:gap-4 custom:max-lg:gap-14 gap-10 max-lg:items-center max-lg:p-[5%]">
        <h2 className="text-4xl font-semibold leading-[44px] max-lg:text-center">
          USD & LRD Accounts
        </h2>
        <p className="text-lg opacity-[0.8] max-w-[30rem]">
          Receive payments from your friends, clients, employees, and other
          businesses directly to your own monival account - without hidden fees
        </p>
        <div className=" flex flex-row gap-2 py-3.5 px-[12px] justify-center bg-[#03B7B4] hover:bg-[#5ec6c3] w-full custom:max-lg:w-full custom:w-[200px] rounded-lg">
          <img
            src={"/images/download.png"}
            alt="download"
            className="object-contain"
          />
          <button>Download Monival</button>
        </div>
      </section>
      <div className="flex-1 justify-center flex flex-row gap-0">
        <img
          src={"/images/james.png"}
          alt="download"
          className="max-w-[64%]  custom:object-contain opacity-100 z-[1]"
        />
        <img
          src={"/images/james-trans.png"}
          alt="download"
          className="max-w-[46%]  custom:object-contain ml-[-10%] z-0 self-end rounded-r-3xl custom:translate-x-0 "
        />
      </div>
    </div>
  );
};
