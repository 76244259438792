import React from "react";
import { Screen2A } from "./screen2A";
import { Screen2B } from "./screen2B";

export const Screen2 = () => {
  return (
    <div>
      <Screen2A />
      <Screen2B />
    </div>
  );
};
