import React, { useState } from "react";

export const Screen2B = () => {
  const [color, setColor] = useState(["bg-[#51983E]", "bg-[#273E45]"]);
  return (
    <div className="flex flex-col gap-[2.5rem] items-center mb-10 mt-8">
      <h3 className="text-sm px-[10px] py-[2px] bg-[#D9FFCF] text-black rounded-2xl text-justify ">
        Virtual Cards
      </h3>
      <h1 className="text-4xl font-semibold text-center">
        Create Virtual Dollar Cards
      </h1>
      <p className="text-lg text-center sm:max-w-[580px] opacity-[0.8]">
        Make very fast and secure payments on both local and international
        platforms with your monival virtual card. Experience to believe it.
      </p>
      {color[0] === "bg-[#51983E]" ? (
        <img
          src={`/images/debit-card.png`}
          alt="debit-card"
          className={`object-contain sm:ml-8 max-w-[40%]`}
        />
      ) : (
        <img
          src={`/images/debit-card-2.png`}
          alt="debit-card-2"
          className={`object-contain sm:ml-8 max-w-[40%]`}
        />
      )}

      <div className="flex flex-row mt-[2rem] gap-3">
        <Stroke color={color[0]} setColor={setColor} />
        <Stroke color={color[1]} setColor={setColor} />
      </div>
    </div>
  );
};
const Stroke = ({ color, setColor }) => {
  return (
    <div
      onClick={() => {
        setColor((prev) => {
          if (prev[0] === "bg-[#51983E]") {
            return ["bg-[#273E45]", "bg-[#51983E]"];
          } else {
            return ["bg-[#51983E]", "bg-[#273E45]"];
          }
        });
      }}
      className={`h-[6px] w-7 ${color} rounded-lg hover:cursor-pointer`}
    ></div>
  );
};
